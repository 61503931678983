<template>
  <div class="app-container">
    <!--    搜索区域-->
    <el-card class="filter-container" shadow="never">
      <div>
        <el-form :inline="true" :model="listQuery" size="small" label-width="140px">
          <el-form-item label="标题：">
            <el-input style="width: 230px" v-model="listQuery.keyword" placeholder="请输入" clearable></el-input>
          </el-form-item>
          <el-form-item label="分类：" >
            <el-cascader
                clearable  filterable
                :props="{ label: 'name', value: 'id', checkStrictly: true }"
                v-model="queryProductCateValue"
                :options="productCateOptions">
            </el-cascader>
          </el-form-item>
        </el-form>
      </div>
      <div style="margin-left: 55px;">
        <el-button type="primary" size="small" @click="handleSearchList()">查询</el-button>
        <el-button size="small" @click="handleResetSearch()">重置</el-button>
      </div>
    </el-card>
    <!--    功能区域-->
    <div style="margin: 10px 0">
      <el-button type="primary" @click="handleBatchAddFilePrice" >批量审核</el-button>
      <el-button type="primary" @click="handleBatchFileReturn" >批量驳回</el-button>
      <el-button type="danger" @click="deleteBatch" >批量删除</el-button>
    </div>
    <!--    表格区域-->
    <div class="table-container">
      <el-table ref="fileTable"
                :data="tableData"
                style="width: 100%;"
                @selection-change="handleSelectionChange"
                v-loading="listLoading" border>
        <el-table-column type="selection" width="60" align="center"></el-table-column>
        <el-table-column label="编号" width="120" align="center">
          <template #default="scope">{{scope.row.id}}</template>
        </el-table-column>
        <el-table-column label="分类"  align="center">
          <template #default="scope">{{scope.row.categoryName}}</template>
        </el-table-column>
        <el-table-column label="标题"  align="center">
          <template #default="scope">{{scope.row.title}}</template>
        </el-table-column>
        <el-table-column label="文件名称"  align="center">
          <template #default="scope">{{scope.row.file_name}}</template>
        </el-table-column>
        <el-table-column label="文件类型"  align="center">
          <template #default="scope">{{scope.row.file_type}}</template>
        </el-table-column>
        <el-table-column label="发布时间" align="center">
          <template #default="scope">{{scope.row.release_time}}</template>
        </el-table-column>
        <el-table-column label="文件价格" align="center">
          <template #default="scope">{{scope.row.file_price}}</template>
        </el-table-column>
        <el-table-column label="文件归属者" align="center">
          <template #default="scope">{{scope.row.fileUserName}}</template>
        </el-table-column>
        <el-table-column label="操作" width="400" align="center">
          <template #default="scope">
            <el-button size="mini" type="primary" @click="handlePreview(scope.row)" >预览</el-button>
            <el-button size="mini" type="primary" @click="handleDownload(scope.row)" >下载</el-button>
            <el-button size="mini" type="primary" @click="handleUpdatePrice(scope.row)" >审核通过</el-button>
            <el-button size="mini" type="primary" @click="handleReturnFile(scope.row)" >审核驳回</el-button>
            <el-button size="mini" type="danger" @click="handleDelete(scope.row)" >删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!--    分页-->
    <div style="margin: 10px 0">
      <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          layout="total, sizes,prev, pager, next,jumper"
          :page-size="listQuery.pageSize"
          :page-sizes="[5,10,15,20,50,100,200,500,1000]"
          :current-page.sync="listQuery.pageNum"
          :total="total">
      </el-pagination>
    </div>

    <el-dialog
        title="审核通过"
        v-model="dialogVisibleFilePrice"
        width="40%">
      <el-form :model="fileModel"
               ref="fileFrom"
               label-width="150px"
               size="small">
        <el-form-item label="分类：">
          <el-cascader
              style="width: 80%"
              clearable  filterable
              :props="{ label: 'name', value: 'id', checkStrictly: true }"
              v-model="selectProductCateValue"
              :options="productCateOptions">
          </el-cascader>
        </el-form-item>
        <el-form-item label="价格：">
          <el-input-number v-model="fileModel.file_price" style="width: 80%" clearable></el-input-number>
        </el-form-item>
      </el-form>
      <template #footer>
          <span class="dialog-footer">
            <el-button @click="dialogVisibleFilePrice = false">取 消</el-button>
            <el-button type="primary" @click="handleBatchAddFilePriceConfirm('fileFrom')">审核通过</el-button>
          </span>
      </template>
    </el-dialog>

    <el-dialog
        title="审核驳回"
        v-model="dialogVisibleFileReturn"
        width="40%">
      <el-form :model="fileModel"
               ref="fileReturnFrom"
               label-width="150px"
               size="small">
        <el-form-item label="驳回原因：">
          <textarea v-model="fileModel.reason" style="width: 80%" rows="5"></textarea>
        </el-form-item>
      </el-form>
      <template #footer>
          <span class="dialog-footer">
            <el-button @click="dialogVisibleFileReturn = false">取 消</el-button>
            <el-button type="primary" @click="handleBatchFileReturnConfirm('fileReturnFrom')">驳回</el-button>
          </span>
      </template>
    </el-dialog>

  </div>
</template>

<script>

import {
  fetchList,
  batchCheckFile, batchReturnFile
} from "@/api/userFile";
import {fetchAllCateWithChildren } from "@/api/category";
import {
  batchDeleteFile,
  deleteBatchUploadFile,
  deleteBatchUploadPicFile,
  deleteFile,
  deleteUploadFile,
  deleteUploadPicFile
} from "@/api/file";

const defaultListQuery = {
  pageNum: 1,
  pageSize: 5,
  keyword: null,
  category: null,
};

const defaultFile = {
  title: null,
  category: null,
  attachment: null,
  pic: null,
  original_name: null,
  file_name: null,
  file_type: null,
  comment: null,
  release_time: null,
  reason: null,
  file_price: 0,
};

export default {
  name: 'UserFile',
  components: {},
  data() {
    return {
      listQuery: Object.assign({}, defaultListQuery),
      orderStatusOptions: [],
      tableData: [],
      total: null,
      listLoading: false,
      ids: [],
      attachments: [],
      dialogVisible: false,
      dialogVisibleCate: false,
      dialogVisibleFilePrice: false,
      dialogVisibleFileReturn: false,
      dialogVisiblePic: false,
      enlargedImageUrl: '',
      isEditFlag: false,
      isEditPicFlag: false,
      fileModel: Object.assign({}, defaultFile),
      fileListShowPic:[],
      fileListPic:[],
      selectProductCateValue: null,
      batchCategory: null,
      queryProductCateValue: null,
      productCateOptions: [],
    }
  },
  created() {
    this.getTableList()
  },
  methods: {

    //重置
    handleResetSearch() {
      this.listQuery = Object.assign({}, defaultListQuery);
      this.queryProductCateValue = null;
      this.getTableList();
    },
    //查询
    handleSearchList() {
      this.listQuery.pageNum = 1;
      this.getTableList();
    },
    //多选
    handleSelectionChange(val){
      this.ids = val.map(v => v.id);
      this.attachments = val.map(v => v.attachment);
    },
    // 改变当前每页的个数触发
    handleSizeChange(val) {
      this.listQuery.pageNum = 1;
      this.listQuery.pageSize = val;
      this.getTableList();
    },
    // 改变当前页码触发
    handleCurrentChange(val) {
      this.listQuery.pageNum = val;
      this.getTableList();
    },

    //列表
    getTableList() {
      this.listLoading = true;
      if(this.queryProductCateValue != null){
        this.listQuery.category = this.queryProductCateValue[this.queryProductCateValue.length - 1];
      }else{
        this.listQuery.category = this.queryProductCateValue;
      }

      fetchList(this.listQuery).then(res => {
        if (res.code === '0') {
          this.listLoading = false;
          this.tableData = res.data.records;
          this.total = res.data.total;
        }

      })

      fetchAllCateWithChildren().then(res => {
        let responseData = res.data;
        this.productCateOptions = [];
        for (let i in responseData ){
          this.productCateOptions.push(responseData[i])
        }
      })

    },

    //批量设置文件价格路由
    handleBatchAddFilePrice() {
      if (!this.ids.length) {
        this.$message.warning("请先选择数据！")
        return
      }
      this.dialogVisibleFilePrice= true;
      this.fileModel.file_price = 0;
    },
    //批量驳回文件
    handleBatchFileReturn() {
      if (!this.ids.length) {
        this.$message.warning("请先选择数据！")
        return
      }
      this.dialogVisibleFileReturn= true;
      this.fileModel.reason = null;
    },
    //修改文件价格
    handleUpdatePrice(row) {
      console.log(row)
      this.dialogVisibleFilePrice = true;
      this.fileModel = Object.assign({},row);
      this.fileModel.file_price = parseFloat(row.file_price);
      if(row.category == 0){
        this.fileModel.category = null;
      }
      this.selectProductCateValue = row.category;
    },
    //驳回
    handleReturnFile(row) {
      this.dialogVisibleFileReturn = true;
      this.fileModel = Object.assign({},row);
      this.fileModel.reason = null;
    },
    //预览文件
    handlePreview(row){
      const url = 'https://view.xdocin.com/view?src=https://hbquan.com/'+row.attachment;
      window.open(url,'_blank');

    },
    //下载文件
    handleDownload(row){
      this.downloadFile(row.attachment,row.file_name);
    },
    // 下载
    downloadFile(url, fileName) {
      const a = document.createElement("a");
      a.setAttribute("href", url)
      a.setAttribute("download", fileName)
      a.setAttribute("target", "_blank")
      let clickEvent = document.createEvent("MouseEvents");
      clickEvent.initEvent("click", true, true);
      a.dispatchEvent(clickEvent);
    },




    //批量设置文件价格
    handleBatchAddFilePriceConfirm() {
      if(this.fileModel.id){
        this.ids.push(this.fileModel.id);
      }

      if (!this.ids.length) {
        this.$message.warning("请先选择数据！")
        return
      }


      if(this.selectProductCateValue != null){
        this.batchCategory = this.selectProductCateValue[this.selectProductCateValue.length - 1];
      }else{
        this.batchCategory = 0;
      }

      this.$confirm('确定审核吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let data =  {
          "dataId": this.ids,
          "filePrice": this.fileModel.file_price,
          "fileCate": this.batchCategory,
        };

        batchCheckFile(data).then(res => {
          if (res.code === '0') {
            this.$message({
              message: '审核成功',
              type: 'success',
            });
          }else {
            this.$message({
              type: "error",
              message: res.msg
            })
          }
          this.dialogVisibleFilePrice =false;
          this.getTableList();
        });

      });
    },
    //批量驳回提交
    handleBatchFileReturnConfirm() {
      if(this.fileModel.id){
        this.ids.push(this.fileModel.id);
      }

      if (!this.ids.length) {
        this.$message.warning("请先选择数据！")
        return
      }

      this.$confirm('确定驳回吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let data =  {
          "dataId": this.ids,
          "reason": this.fileModel.reason,
        };

        batchReturnFile(data).then(res => {
          if (res.code === '0') {
            this.$message({
              message: '已驳回',
              type: 'success',
            });
          }else {
            this.$message({
              type: "error",
              message: res.msg
            })
          }
          this.dialogVisibleFileReturn =false;
          this.getTableList();
        });

      });
    },
    //批量删除
    deleteBatch() {
      if (!this.ids.length) {
        this.$message.warning("请选择数据！")
        return
      }
      this.$confirm('确定删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let data =  {
          "dataId": this.ids,
        };

        //删除附件
        deleteBatchUploadFile(data);
        deleteBatchUploadPicFile(data);

        batchDeleteFile(data).then(res => {
          if (res.code === '0') {
            this.$message({
              type: "success",
              message: "批量删除成功"
            })
          } else {
            this.$message({
              type: "error",
              message: res.msg
            })
          }
          this.getTableList();
        });
      });
    },
    //删除
    handleDelete(row){
      this.$confirm('确定删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let data =  {
          "dataId": row.id,
        };
        deleteUploadFile(data);
        deleteUploadPicFile(data);

        deleteFile(data).then(res => {
          if (res.code === '0') {
            this.$message({
              type: "success",
              message: "删除成功"
            })
          } else {
            this.$message({
              type: "error",
              message: res.msg
            })
          }
          this.getTableList();
        });
      });
    },

  }
}
</script>
